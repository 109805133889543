.ace_editor, .ace_editor *{
font-family: "Monaco", "Menlo", "Ubuntu Mono", "Droid Sans Mono", "Consolas", monospace !important;
font-size: 15px !important;
font-weight: 400 !important;
letter-spacing: 0 !important;
}

.file_uploader{
    width: min(100%, 500px) !important;
    height: 100px !important;
}